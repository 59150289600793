import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Menu from "./Menu";

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileMenuOpen: false,
    };
  }

  onMobileMenuClick = () => {
    this.setState({
      mobileMenuOpen: !this.state.mobileMenuOpen,
    });
  };

  hideMobileMenu = () => {
    this.setState({
      mobileMenuOpen: false,
    });
  };

  _getLink = (link) => {
    return (this.getLanguage() === "en" ? "/en" : "") + link;
  };

  _getAlternateLink = () => {
    const path = window.location.pathname;

    let link =
      this.getLanguage() === "en"
        ? path.replace("/en", "")
        : "/en" + (path === "/" ? "" : path);

    return link.length > 0 ? link : "/";
  };

  getLanguage = () => {
    return window.location.pathname.indexOf("/en") !== -1 ? "en" : "hr";
  };

  render() {
    const { lang } = this.props;

    let menuClassnames = ["menu"];

    if (this.state.mobileMenuOpen) {
      menuClassnames.push("menu--open");
    }

    return (
      <>
        <header className="header">
          <div className="header__logo">
            <NavLink onClick={this.hideMobileMenu} exact to={this._getLink("")}>
              <img
                alt="Mentoring Plus"
                src="/assets/images/mentoringplus.svg"
              />
            </NavLink>
          </div>
          <div className="header__menu">
            <a
              style={{ display: "none" }}
              onClick={this.hideMobileMenu}
              href={this._getAlternateLink()}
              className="header__menu__link header__menu__link--clean"
            >
              <img
                onClick={this.onMobileMenuClick}
                alt=""
                src="/assets/images/globe.svg"
              />
              {this.getLanguage() === "en" ? "HR" : "EN"}
            </a>
            <NavLink
              onClick={this.hideMobileMenu}
              to={this._getLink("/contact")}
              className="header__contact__link"
            >
              {lang.headerContact}
            </NavLink>
            <span
              onClick={this.onMobileMenuClick}
              className="header__menu__link"
            >
              {lang.headerMenu}
              <img
                onClick={this.onMobileMenuClick}
                alt=""
                src="/assets/images/menu.svg"
              />
            </span>
          </div>
        </header>
        <div className={menuClassnames.join(" ")}>
          <div className="menu__close">
            <span className="header__menu__link" onClick={this.hideMobileMenu}>
              {lang.headerClose}
              <img
                onClick={this.onMobileMenuClick}
                alt=""
                src="/assets/images/menu.svg"
              />
            </span>
          </div>
          <Menu onClick={this.onMobileMenuClick} lang={lang} />
        </div>
      </>
    );
  }
}
