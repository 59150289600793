import React, { Component } from 'react';
import Menu from './Menu';

export default class Footer extends Component {
	render() {

		const {
			lang
		} = this.props;

		return (
			<footer className="footer">
				<div className="footer_nav">
					<Menu lang={lang} />
				</div>
				<div className="footer__company">
					<div className="footer__company__icon" />
					<div className="footer__company__info">Mentoring Plus © 2021</div>
				</div>
			</footer>
		)
	}
}