import React, { Component } from 'react'

export default class Page extends Component {
  constructor(props) {
    super(props)

    this.state = {
      title: "",
      content: "",
    }
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.loadData();
    }
  }

  loadData = () => {
    let page = this.props.location.pathname.replaceAll('/', '.')

    fetch(`/assets/data/page${page}.json`)
      .then((resp) => resp.json())
      .then(data => {
        this.setState({
          image: data.image,
          title: data.title,
          intro: data.intro,
          content: data.content
        })
      });

    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  render() {

    const {
      image,
      title,
      intro,
      content
    } = this.state;

    return (
      <div className="page">
        <div className="page__intro" style={{ backgroundImage: `url(${image ? image : '/assets/backgrounds/home.jpg'})` }}>
          <div className="page__intro__content">
            <h1>{title}</h1>
            <div dangerouslySetInnerHTML={{ __html: intro }} />
          </div>
        </div>
        <div className="page__content">
          <article dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </div>
    );
  }
}