import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null
    };
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.loadData();
    }
  }

  loadData = () => {
    fetch(`/assets/data/page.${this.getLanguage() === 'en' ? 'en.' : ''}home.json`)
      .then((resp) => resp.json())
      .then(data => {
        this.setState({
          data: data,
          language: this.getLanguage()
        })
      });
  }

  getLanguage = () => {
    return window.location.pathname.indexOf('/en') !== -1 ? 'en' : 'hr';
  }

  render() {

    const {
      data
    } = this.state;

    if (data === null) {
      return <></>;
    }

    return (
      <div className="page">
        <div className="page__intro" style={{ backgroundImage: 'url(/assets/backgrounds/home.jpg)' }}>
          <div className="page__intro__content">
            <h1>{data.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: data.intro }} />
          </div>
        </div>
        <div className="page__content">
          <h2>{data.story.title}</h2>
          <div className="change__list">
            {data.story.items.map((item, key) => (
              <>
                {key % 2 === 0 &&
                  <div key={key} className="change__row">
                    <div className="change">
                      <div className="change__icon" />
                      <div className="change__content" dangerouslySetInnerHTML={{ __html: item }} />
                    </div>
                    <div className="change">
                      <div className="change__icon" />
                      <div className="change__content" dangerouslySetInnerHTML={{ __html: data.story.items[key + 1] }} />
                    </div>
                  </div>
                }
              </>
            ))}
          </div>
          <div className="change__cta">
            <NavLink className="button" to={data.story.ctaLink}>{data.story.cta}</NavLink>
          </div>
        </div>
      </div>
    );
  }
}