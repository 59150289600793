import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class Blog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      image: null,
      title: "",
      intro: "",
      items: [],
    }
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.loadData();
    }
  }

  loadData = () => {
    let page = this.props.location.pathname.replaceAll('/', '.')

    fetch(`/assets/data/page${page}.json`)
      .then((resp) => resp.json())
      .then(data => {
        this.setState({
          image: data.image,
          title: data.title,
          intro: data.intro,
          items: data.items
        })
      });

    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  render() {

    const {
      image,
      title,
      intro,
      items
    } = this.state;

    return (
      <div className="page">
        <div className="page__intro" style={{ backgroundImage: `url(${image ? image : '/assets/backgrounds/home.jpg'})` }}>
          <div className="page__intro__content">
            <h1>{title}</h1>
            <div dangerouslySetInnerHTML={{ __html: intro }} />
          </div>
        </div>
        <div className="page__content">
          {items &&
            <>
              {items.map((item, key) => (
                <NavLink to={item.id} key={key} className="blog">
                  <div className="image" style={{ backgroundImage: `url(${item.image ? item.image : '/assets/backgrounds/home.jpg'})` }}/>
                  <div className="content">
                    <h2>{item.title}</h2>
                    <p>{item.intro}</p>
                  </div>
                </NavLink>
              ))}
            </>
          }
        </div>
      </div>
    );
  }
}